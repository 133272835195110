import React from "react";
import { partnersDetails } from "../../data/partnersDetails";
import { useParams } from "react-router-dom";
import {
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import "./PartnersData.css";

const PartnersData = () => {
  const params = useParams();
  const partnerId = params.id;
  const partner = partnersDetails.find((partner) => partner.id === partnerId);

  if (!partner) {
    return <Typography variant="h5">Partner not found</Typography>;
  }

  return (
    <Container maxWidth="lg" className="partners-data-main-container">
      <Container className="partners-data-container" maxWidth="md">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <CardContent className="partner-card-content">
              <CardMedia
                component="img"
                src={partner.image}
                className="partner-image"
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {partner.name}
              </Typography>
              <Typography variant="subtitle1">{partner.designation}</Typography>
            </CardContent>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <div
              style={{
                backgroundColor: "rgba(120, 65, 20, 1);",

                padding: "20px",
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontSize: "1.2rem", color: "#FFF" }}
              >
                {partner.details}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default PartnersData;
