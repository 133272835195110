import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import { newsbriefings } from "../../data/newsbriefings";
import "./News.css";
import React from "react";

const News = () => {
  return (
    <Container sx={{ textAlign: "center" }}>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          textTransform: "uppercase",
          fontSize: { xs: "32px", sm: "36px", md: "48px" },
          lineHeight: { xs: "40px", sm: "44px", md: "58px" },
          fontWeight: "300",
          margin: "30px 0",
        }}
      >
        NEWS & BRIEFINGS
      </Typography>
      <Grid container spacing={3}>
        {newsbriefings.map((card) => (
          <Grid item xs={12} sm={6} md={4} key={card.id}>
            <Card className="news-cards-container">
              <CardMedia component="img" src={card.image} alt="Image 2" />
              <CardContent sx={{ textAlign: "left" }}>
                <Typography className="card-date-text">{card.date}</Typography>
                <Typography className="card-description-text">
                  {card.description.substring(0, 65)}...
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        sx={{
          backgroundColor: "#09154e",
          color: "white",
          marginTop: "10px",
          fontFamily: "Lora",
          fontSize: { xs: "16px", md: "20px" },
          fontWeight: "400",
          textTransform: "inherit",
          padding: "5px 20px",
          margin: "50px 0",
        }}
      >
        View More
      </Button>
    </Container>
  );
};

export default News;
