import {
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import IMG1 from "../../assets/images/img_lna.jpg";
import IMG2 from "../../assets/images/img_tnrc.jpg";
import React from "react";

const PracticeArea = () => {
  return (
    <Container sx={{ textAlign: "center" }}>
      <Typography
        sx={{
          fontFamily: "Montserrat",
          textTransform: "uppercase",
          fontSize: { xs: "36px", sm: "48px", md: "56px" },
          lineHeight: { xs: "44px", sm: "54px", md: "64px" },
          fontWeight: "300",
          padding: "50px 0",
        }}
      >
        PRACTICE AREA
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{ paddingBottom: { xs: "20px", md: "80px" } }}
      >
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia component="img" src={IMG1} alt="Image 1" />
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                sx={{
                  fontFamily: "montserrat",
                  fontWeight: "300",
                  fontSize: { xs: "16px", sm: "18px", md: "20px" },
                }}
              >
                LITIGATION & ARBITRATION
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardMedia component="img" src={IMG2} alt="Image 2" />
            <CardContent>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                sx={{
                  fontFamily: "montserrat",
                  fontWeight: "300",
                  fontSize: { xs: "16px", sm: "18px", md: "20px" },
                }}
              >
                TRANSACTION & REGULATORY COMPLIANCE
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PracticeArea;
