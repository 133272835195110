import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

const navItems = [
  { label: "HOME", path: "/" },
  { label: "LEGAL TALKS", path: "https://blogs.lawfare.co/", anchor: true },
  { label: "TEAMS", path: "#teams", anchor: true },
  { label: "CITIZEN ADVISORY CENTER", path: "#citizen-advisory", anchor: true },
];

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerContent = (
    <Box
      sx={{
        width: 240,
        flexShrink: 0,
        bgcolor: "#f1f1f1",
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem
            key={item.label}
            button
            component={RouterLink}
            to={item.path}
            {...(item.anchor && {
              to: item.path,
              smooth: true,
              spy: true,
              offset: -64,
            })} // Add smooth scroll behavior
          >
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1, position: "sticky", top: 0, zIndex: 1000 }}>
      <AppBar position="static" sx={{ bgcolor: "#3F6251", marginBottom: 0 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component={RouterLink}
            to="/"
            sx={{
              flexGrow: 1,
              textDecoration: "none",
              color: "white",
              fontFamily: "Playwrite MX,cursive",
              font: "3em",
              fontWeight: 400,
              padding: "10px",
            }}
          >
            Lawfare.Co
          </Typography>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.label}
                color="inherit"
                component={item.anchor ? "a" : RouterLink} // Use anchor tag for scrolling link
                href={item.anchor ? item.path : undefined} // Set href for anchor tag
                to={!item.anchor ? item.path : undefined} // Set to prop for RouterLink
                sx={{
                  color:
                    item.label === "CITIZEN ADVISORY CENTER"
                      ? "white"
                      : "black", // Unique color for the action button
                  fontWeight:
                    item.label === "CITIZEN ADVISORY CENTER" ? 700 : 600, // Unique font weight for the action button
                  fontSize: "20px",
                  fontFamily: "serif",
                  bgcolor:
                    item.label === "CITIZEN ADVISORY CENTER"
                      ? "#9b8b7c"
                      : "transparent", // Unique background color for the action button
                  "&:hover": {
                    bgcolor:
                      item.label === "CITIZEN ADVISORY CENTER"
                        ? "#504840"
                        : "transparent", // Unique hover color for the action button
                  },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{ "& .MuiDrawer-paper": { bgcolor: "#f1f1f1" } }}
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

export default Navbar;
