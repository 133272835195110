import taimoor from "../assets/images/taimoor_raza.jpg"
import mariyam from "../assets/images/mariyam-qurban.jpg"
import fatimamehr from "../assets/images/fatima_mehr.jpg"
import ameerhamza from "../assets/images/ameer_hamza.jpg"
import ahmarnazar from "../assets/images/ahmar_nazar.jpg"
import hajabfatima from "../assets/images/hajab_fatima.jpg"
import salehawaqar from "../assets/images/saleha_waqar.jpg"
import hafsaahmad from "../assets/images/hafsa_ahmad.jpg"
import fazeelatariq from "../assets/images/fazeela_tariq.jpg"
import ghulamadnan from "../assets/images/ghulam_adnan.jpg"
import nooreiman from "../assets/images/noor_e_iman.jpg"
import fatimaghauri from "../assets/images/fatim_ghauri.jpg"
export const partners = [
  {
    id: 1,
    image: taimoor,
    name: "Taimoor Raza Sultan",
  },
  {
    id: 2,
    image: mariyam,
    name: "Mariyam Qurban",
  },
  {
    id: 3,
    image: fatimamehr,
    name: "Fatima Mehr",
  },
  {
    id: 4,
    image: ameerhamza,
    name: "Muhammad Ameer Hamza Khan",
  },
  {
    id: 5,
    image: ahmarnazar,
    name: "Muhammad Ahmar Nazar",
    description: "View Profile",
  },
  {
    id: 6,
    image: hajabfatima,
    name: "Hajab Fatima",
    description: "View Profile",
  },
  // {
  //   id: 7,
  //   image: amnarehman,
  //   name: "Amna Rehman",
  //   description: "View Profile",
  // },
  {
    id: "8",
    image: salehawaqar,
    name: "Saleha Waqar",
    description: "View Profile",
  },
  {
    id: "9",
    image: hafsaahmad,
    name: "Hafsa Ahmad",
    description: "View Profile",
  },
  {
    id: "10",
    image: fazeelatariq,
    name: "Fazeela Tariq",
    description: "View Profile",
  },
  {
    id: "11",
    image: ghulamadnan,
    name: "Ghulam Muhammad Adnan",
    description: "View Profile",
  },
  {
    id: "12",
    image: nooreiman,
    name: "Noor-e-Iman",
    description: "View Profile",
  },
  {
    id: "13",
    image: fatimaghauri,
    name: "Fatima Ahmad Ghauri",
    description: "View Profile",
  },
  // {
  //   id: "15",
  //   image: hareemjabar,
  //   name: "Hareem Abdul Jabbar ",
  //   description: "View Profile",
  // },
  // {
  //   id: "16",
  //   image: joharimam,
  //   name: "Adv Johar Imam ",
  //   description: "View Profile",
  // },
];
