import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import "./App.css";
import HomeMain from "./routes/HomeMain";
import Navbar from "./components/navbar/Navbar";
import PartnersData from "./components/partnersData/PartnersData";
import Footer from "./components/footer/footer";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path="/" element={<HomeMain />} />
          <Route path="/partner-detail/:id" element={<PartnersData />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
