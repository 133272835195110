import {
  Card,
  Container,
  Grid,
  Typography,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { partners } from "../../data/partners";
import "./Partners.css";
import Associates from "../assoicates/Associates";

const Partners = () => {
  return (
    <div className="partners-main-container" id="teams">
      <Typography
        sx={{
          textAlign: "center",
          fontFamily: "Montserrat",
          textTransform: "uppercase",
          fontSize: { xs: "32px", sm: "36px", md: "48px" },
          lineHeight: { xs: "40px", sm: "44px", md: "58px" },
          fontWeight: "300",
          padding: { xs: "20px 0", sm: "30px 0", md: "50px 0" },
        }}
      >
        OUR ADVOCATES
      </Typography>
      <Grid
        container
        sx={{ width: "90vw", margin: "0 auto", textAlign: "center" }}
        spacing={3}
      >
        {partners.map((partner) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={partner.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              className="cards-container"
              sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
                color: "white",
              }}
            >
              <CardContent className="partner-container">
                <CardMedia
                  component="img"
                  src={partner.image}
                  alt="Images"
                  className="partner-images-container"
                  sx={{
                    width: { xs: "180px", sm: "220px", md: "270px" },
                    height: { xs: "180px", sm: "220px", md: "270px" },
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    textTransform: "uppercase",
                    fontSize: { xs: "20px", sm: "24px", md: "26px" },
                  }}
                >
                  {partner.name}
                </Typography>
                <Link
                  to={`/partner-detail/${partner.id}`}
                  className="partner-link-btn"
                >
                  View Profile
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Container className="border-partner-associates"></Container>
      <Associates />
    </div>
  );
};

export default Partners;
