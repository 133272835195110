import taimoor from "../assets/images/taimoor_raza.jpg"
import mariyam from "../assets/images/mariyam-qurban.jpg"
import fatimamehr from "../assets/images/fatima_mehr.jpg"
import ameerhamza from "../assets/images/ameer_hamza.jpg"
import ahmarnazar from "../assets/images/ahmar_nazar.jpg"
import hajabfatima from "../assets/images/hajab_fatima.jpg"
import salehawaqar from "../assets/images/saleha_waqar.jpg"
import hafsaahmad from "../assets/images/hafsa_ahmad.jpg"
import fazeelatariq from "../assets/images/fazeela_tariq.jpg"
import ghulamadnan from "../assets/images/ghulam_adnan.jpg"
import nooreiman from "../assets/images/noor_e_iman.jpg"
import fatimaghauri from "../assets/images/fatim_ghauri.jpg"
export const partnersDetails = [
  {
    id: "1",
    image: taimoor,
    name: "Taimoor Raza Sultan",
    designation: "Founder",
    details:
      "Mr. Sultan is a lawyer by profession. He is a Partner at Sultan & Sultans Law Firm, with a rich and varied career. Beyond legal practice, he is a published author, and has written on crucial constitutional and legal questions. He has notable affiliations with national and international research centers, including CORD and RCIL & HR. He has spoken to many national and international conferences and community awareness lectures at different universities, on a range of themes, primarily Human Rights. His professional journey encompasses a spectrum of experiences, ranging from illustrious law firms to pivotal roles within governmental institutions like the Attorney General's Office and the Federal Ministry of Law and Justice.",
  },
  {
    id: "2",
    image: mariyam,
    name: "Mariyam Qurban",
    designation: "Co-Founder",
    details: "Miss Mariyam Qurban is a final-year law student whose exceptional accomplishments serve as a testament to her prowess within the realm of law and jurisprudence. She has developed keen interest in International Humanitarian Law and published her research in many notable journals. She is a detail-oriented researcher with unswerving ardor for novel ideas. In her capacity as the Vice-President of the esteemed Kinnaird NHLD Mooting Society, Miss Qurban consistently exhibits a standard of advocacy that is nothing short of exceptional. Her active involvement in both National and International moot court competitions stands as a reflection of her steadfast commitment to the refinement of her legal craft. Her innate curiosity drives her to probe into legal conundrums with unparalleled zeal. Her adeptness in dissecting multifaceted legal matters distinguishes her as a true law student.    Miss Qurban’s mission to propagate legal enlightenment and ensure access of justice to the underprivileged is vividly exemplified by her co-founding of the visionary think tank known as Lawfare. Through this innovative platform, she intends to explore the multifarious dimensions of both international and domestic jurisprudence, significantly contributing to the overarching intellectual discourse within the legal fraternity. Her commitment reverberates further as she diligently endeavors to make legal knowledge accessible to a wider audience, ensuring inclusivity for all. Possessing an exceptional command over mooting skills, an inherent prowess in research and an unfaltering dedication to the cause of legal education, Miss Qurban serves as an inspirational figure for many."
},
  {
    id: "3",
    image: fatimamehr,
    name: "Fatima Mehr ",
    designation: "Publication Outreach Director",
    details: "My name is Fatima Mehr, and I am currently pursuing a Bachelor's degree in law from Kinnaird College Lahore. My ultimate aspiration revolves around increasing public awareness regarding their legal rights. I believe in strengthening law and order, eliminating crimes and upholding justice in society. To enhance my knowledge and skills in this field, I recently completed an enriching internship at the National Assembly of Pakistan, and also completed a three day workshop on ADR. Over the past few years, I’ve tried to strike a balance between academics and extracurricular activities. I firmly believe in “All work and no play makes Jill a dull girl” therefore, I have actively participated in several events, such as being a core management team member for the Kinnaird CS Club. Additionally, I showcased my legal acumen by participating in the International Panjnad Moot Competition and securing a prestigious prize. My interests also extend beyond law, as I have developed a profound passion for travelling and reading books. I believe hard work, dedication and consistency leads to the accomplishment of goals. Therefore, I look forward to incredible learning and work experiences."
  },
  {
    id: "4",
    image: ameerhamza,
    name: "Muhammad Ameer Hamza Khan",
    designation: "Operations Director",
    details: "Muhammad Ameer Hamza Khan is a highly accomplished advocate with a diverse background in international law. Working with the ICRC Pakistan, he gained valuable experience in humanitarian law and conflict resolution. His exceptional legal acumen is further evidenced by his active participation in numerous prestigious moot court competitions such as PCL, Jessup, Nuremberg, and HDMCC. With a strong academic foundation from institutions like Bahria University and the University of Massachusetts, he has honed his skills to effectively navigate complex legal challenges.Motivated by a passion for advancing legal research and policy advocacy, Muhammad Ameer Hamza Khan joined the Lawfare think tank. His goal is to leverage his expertise to contribute to innovative solutions and promote the rule of law on a global scale, working towards a more just and equitable world."
  },
  {
    id: "5",
    image: ahmarnazar,
    name: "Muhammad Ahmar Nazar",
    designation: "Communications Director",
    details: "I am Muhammad Ahmar Nazar, a passionate law enthusiast currently pursuing my LL.B (Hons) degree at NUST. With a keen interest in Constitutional Law and International Law, I have dedicated myself to becoming an advocate for justice and empowering basic human rights in Pakistan. My time at NUST Law Society and participation in prestigious competitions like the Henry Dunant Moot Court, Denning National Moot Court, and LUMS International Moot Court have further enriched my understanding of the legal world. One of my core strengths lies in my ability to engage in meaningful conversations, be it with fellow legal professionals or the common person seeking clarity in legal matters. I believe in bridging the gap between the legal profession and the layman's perspective, shaping a better image of the legal field as a force for positive change in society. As I embark on this journey, I am excited to join Lawfare, a platform that aligns perfectly with my objectives. Together with this esteemed team, I aim to contribute efficiently to the empowerment of basic human rights in Pakistan and beyond. Through Lawfare, I am determined to advocate for justice, foster a deeper understanding of legal entitlements, and make a meaningful impact on the lives of individuals and communities."
  },
  {
    id: "6",
    image: hajabfatima,
    name: "Hajab Fatima ",
    designation: "Assistant Publication Director",
    details: "Greetings, I'm Hajab Fatima, a law student driven by a keen interest in women's issues, colonialism, and everything in between. Embracing the role of a member within Lawfare, I am resolute in delving into the intricate relationship between legal frameworks, gender (in)equality, and the enduring legacy of colonialism. Through insightful analysis and a determination to promote meaningful change, I am steadfast in making valuable contributions that further the dialogue on these vital subjects, all in pursuit of a fairer and more inclusive global society."
  },
  // {
  //   id: "7",
  //   image: amnarehman,
  //   name: "Amna Rehman",
  //   designation: "Marketing Director",
  //   details: "Ms. Amna Rehman, a dedicated final year law student at Kinnaird College for Women University, is making impressive strides in her academic journey. She has enthusiastically engaged in mooting both as an adjudicator and a participant, showcasing her commitment to honing her legal skills. Beyond this, she is passionately working towards contributing to the legal discourse by actively seeking publication opportunities in renowned international journals. Her proactive approach exemplifies her belief in the value of these endeavors, which she recognizes as instrumental in facilitating her continuous learning and growth. Driven by a strong passion for legal discourse and the desire to contribute meaningfully to the legal landscape, she saw Lawfare as the perfect platform to engage in insightful discussions, research, and policy formulation. Through her active participation in this think tank, she aims to further enhance her legal acumen and make a positive impact on the legal community."
  // },
  {
    id: "8",
    image: salehawaqar,
    name: "Saleha Waqar",
    designation: "Publications Director",
    details: "Meet Saleha Waqar, a determined and accomplished final year law student with a passion for making a meaningful impact on society. Through active participation in moot court competitions, Saleha has honed her skills in persuasive communication and legal argumentation. Her fascination with constitutional and criminal law reflects her deep-seated interest in issues of justice and fairness. Alongside her academic pursuits, Saleha's commitment to aiding others shines through, as she consistently seeks ways to lend a helping hand. Her commitment to contributing positively to society is further evident in her ongoing work authoring papers that aim to bring fresh insights to the legal field. As she prepares to embark on her professional journey, Saleha Waqar's dedication to the pursuit of justice and her comprehensive understanding of the law stand as a testament to her potential to create meaningful change in the legal landscape."
  },
  {
    id: "9",
    image: hafsaahmad,
    name: "Hafsa Ahmad",
    designation: "Assistant Research  Director",
    details: "I am Hafsa, a fourth-year law student at NUST. I have been a part of various national and international moot court competitions. I am keenly interested in in human rights, public international law, and criminal law, which is grounded in my belief in the transformative power of law to shape societies and protect individual rights. Recognizing the intricate interconnectedness of these areas, I am committed to understanding the nuances and complexities that define them. My passion stems from an innate desire to contribute to the advancement of justice, equality, and accountability on a global scale.With the Lawfare, I am even more excited to foster legal discourse and my understanding on matters of great pertinence. My participation in Lawfare would not only provide me with an opportunity to contribute my insights and research skills but would also allow me to learn from fellow legal minds who share a passion for addressing the intricate intersections of law and society. By collaborating with like-minded professionals, I aim to broaden my perspectives and develop innovative solutions to complex legal problems.."
  },
  {
    id: "10",
    image: fazeelatariq,
    name: "Fazeela Tariq",
    designation: "Event Managment  Director",
    details: "Meet Fazeela Tariq, a dedicated final year law student with a knack for thorough research and persuasive communication. With a string of successful moot court engagements under her belt and internships at CFHR, Alliance Law and Consultants, and Minto and Mirza Advocates and Solicitors, she brings a practical understanding of legal proceedings and client interactions. Her diverse legal knowledge spans various areas, and she's passionate about staying up-to-date with the latest legal trends. A collaborative team player, she's eager to leverage her skills and experiences to make a meaningful impact in the legal field. Driven by an unwavering passion for the intricacies of law, Fazeela aspires to extend her impact beyond the classroom. Inspired by the dynamic nature of legal challenges, she envisions herself as an asset to a forward-thinking think tank like Lawfare. With a deep-seated commitment to research excellence, combined with her proven mooting proficiency and practical internships, she sees the platform as an opportunity to contribute her insights to innovative legal discourse, shape legal narratives, and actively influence the evolution of legal thought."
  },
  {
    id: "11",
    image: ghulamadnan,
    name: "Ghulam Muhammad Adnan ",
    designation: "Policy and Advocacy  Director ",
    details: "Ghulam Muhammad Adnan is an IMI Qualified and SIMI Accredited mediator from Lahore, Pakistan. He pursued the Bachelor of Laws (LLB) from the University of London from where he is expected to graduate in 2023. He is an Alumni of the Hague Academy of International Law from where he pursued the Summer Course on Private International Law Adnan brings with him a diverse range of expertise in different areas of law. He has experience as an ADR Professional, coach & judge for mediation, negotiation, & arbitration competitions all across the world such as the Vis Moot, Vienna & CDRC Vienna Mediation & Negotiation Competition to name a few. Currently, he is working as a trainee lawyer at the Chamber of Barrister Salman Safdar. As an undergraduate LLB student, he always had a keen interest in research, article writing, and publications. Adnan is excited to be a part of the Lawfare team & eagerly looks forward to the exciting journey ahead."
  },
  {
    id: "12",
    image: nooreiman,
    name: "Noor-e-Iman",
    designation: "Assistant Communication  Director",
    details: " Meet Noor-e-Iman, a dedicated and aspiring law student, whose passion for justice and commitment to understanding the intricate tapestry of legal principles sets them apart. With involvement in a human rights law clinic, contributions to a research center (RSIL), and a valuable internship at a prominent law firm, I have honed my skills in advocating for justice, conducting meticulous research, and navigating the intricacies of legal practice, bringing a blend of academic dedication and practical experience to the legal realm.  This well-rounded background reflects my commitment to making a meaningful impact as a part of this team. Guided by a strong sense of empathy and justice, I am drawn to the study of human rights, with a keen interest in advocating for the protection of fundamental rights and liberties on a global scale. With an unwavering dedication to learning, an empathetic approach to understanding diverse perspectives, and an enthusiasm for critical thinking, I am poised to evolve into a formidable legal scholar and a compassionate advocate for justice."
  },
  {
    id: "13",
    image: fatimaghauri,
    name: "Fatima Ahmad Ghauri",
    designation: " Director HR",
    details: "Fatima Ahmed is a fifth-year law student with a keen passion for corporate and commercial law. Her interest in these areas naturally extended to human resources, leading her to her current role. Furthermore, she has actively participated in research focused on human rights, specifically concentrating on marriage rights. This multifaceted engagement highlights her versatile pursuits, ranging from intricate corporate legal matters to her commitment to advocating for fundamental human rights."
  },
  // {
  //   id: "15",
  //   image: hareemjabar,
  //   name: "Hareem Abdul Jabbar ",
  //   designation: "Social and Political Research Director",
  //   details: "A dynamic individual with a diverse blend of skills and passions, Hareem Abdul Jabbar brings a unique perspective to the world of law and advocacy. With a Bachelor's degree in Chemistry as a foundation, she ventured into content writing for legal niches at Hub Daily, showcasing her adeptness in translating complex subjects into accessible narratives.Hareem's versatility shines through her accomplishments as a debater and writer. Her prowess in articulating ideas extends to her passion for International Politics, Human Rights, History, and International Humanitarian Law. These domains fuel her drive to understand and contribute to global affairs.With a deep-seated inspiration to be a catalyst for change, Hareem's involvement in Lawfare stems from a desire to amplify the propagation of law within society. Her aspirations align seamlessly with her role as a drop in the ocean—each action contributing to the tidal wave of awareness and change in legal discourse. In a world where multidisciplinary expertise is paramount, Hareem Abdul Jabbar's journey exemplifies the convergence of diverse knowledge into a focused pursuit of justice and equity."
  // },
  // {
  //   id: "16",
  //   image: joharimam,
  //   name: "Adv Johar Imam ",
  //   designation: "Chief Research Director",
  //   details: "Mr Johar is a lawyer by profession. He is currently working with SL Associates, a professional chamber specializing in civil and criminal litigation. In addition to various internships at leading law chambers, he has also worked for prominent organizations such as Justice Project Pakistan (JPP) were he conducted research on 7 ATA cases, use of Torture, prison reforms rights of women and children. During his university years, he represented his university in several national and international moot court competitions including the prestigious Phillip. C Jessup International Moot Court competition where he was ranked in the top ten speakers of the national rounds. He has also been called to adjudicate several mooting competitions by various universities including his own alma mater, Quaid-e-Azam Law College and GIKI University. Johar is also a published author and has written extensively on international relations and law."
  // },
];
