import Home from "../components/home/Home";
import PracticeArea from "../components/practiceArea/PracticeArea";
import News from "../components/newsbriefings/News";
import Partners from "../components/partners/Partners";
import Form from "../components/form/Form";

export default function HomeMain() {
  return (
    <>
      <Home />
      <PracticeArea />
      <Partners />
      <News />
      <Form/>
    </>
  );
}
