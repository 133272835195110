import React, { useState } from "react";
import { TextField, Container, Typography, Button } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Form.css";
import route from "../../route";

const Form = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // State to track button disabled state

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set button to disabled state while submitting

    try {
      const response = await axios.post(`${route}api/sendemail`, formData);
      toast.success(response.data.message);
    } catch (error) {
      toast.error("An error occurred while sending the email.");
      console.error("There was an error sending the email!", error);
    } finally {
      setIsSubmitting(false);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ margin: "20px auto", padding: "20px", border: "2px solid black" }}
      id="citizen-advisory"
    >
      <Typography
        variant="h1" // Use variant="h1" for the main heading
        sx={{
          textAlign: "center",
          fontFamily: "Montserrat",
          textTransform: "uppercase",
          fontSize: { xs: "32px", sm: "36px", md: "48px" },
          lineHeight: { xs: "40px", sm: "44px", md: "58px" },
          fontWeight: "bold", // Increase font weight for the main heading
          padding: { xs: "30px 0", md: "50px 0" },
        }}
      >
        CITIZEN ADVISORY CENTER
      </Typography>
      <Typography
        variant="h2" // Use variant="h2" for the subheading
        sx={{
          textAlign: "center",
          fontFamily: "Montserrat",
          // textTransform: "uppercase",
          fontSize: { xs: "22px", sm: "26px", md: "38px" },
          lineHeight: { xs: "40px", sm: "44px", md: "58px" },
          fontWeight: "300", // Keep the font weight lighter for the subheading
          padding: { xs: "30px 0", md: "50px 0" },
        }}
      >
        Seek Free Legal Advice Now!
      </Typography>

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="First Name"
          variant="outlined"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Last Name"
          variant="outlined"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Phone No./ Email"
          variant="outlined"
          name="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Message"
          multiline
          rows={4}
          variant="outlined"
          name="message"
          value={formData.message}
          onChange={handleChange}
          margin="normal"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, width: "100%" }} // Add width: "100%" to stretch the button
          disabled={isSubmitting} // Disable button based on isSubmitting state
        >
          Submit
        </Button>
      </form>
      <ToastContainer />
    </Container>
  );
};

export default Form;
