import news1 from "../assets/images/news1.jpg";
import news2 from "../assets/images/news2.jpeg";
import news3 from "../assets/images/news3.jpg";
import news4 from "../assets/images/news4.jpg";
import Group1 from "../assets/images/Group1.jpg";
export const newsbriefings = [
  {
    id: 1,
    image: news1,
    date: "October 1, 2023",
    description: "MERGER OF RAJA MOHAMMED AKRAM & CO. AND FAISAL & PARTNERS",
  },
  {
    id: 2,
    image: news2,
    date: "September 20, 2023",
    description: "GLOBAL GOVERNMENT CONTRACTING: ASIA PACIFIC",
  },
  {
    id: 3,
    image: news3,
    date: "July 21, 2023",
    description: "ISLAMABAD HIGH COURT ALLOWS CHALLENGE TO SUPER TAX",
  },
  {
    id: 4,
    image: Group1,
    date: "April 28, 2023",
    description:
      "LEGAL FRAMEWORK GOVERNING FOREIGN ARBITRAL AWARDS: FROM HITACHI LIMITED TO PRESENT",
  },
  {
    id: 5,
    image: news3,
    date: "March 20, 2023",
    description: "DELOS GUIDE TO ARBITRATION PLACES: PAKISTAN CHAPTER",
  },
  {
    id: 6,
    image: news4,
    date: " September 18, 2020",
    description:
      "THE INDICATIVE GENERATION CAPACITY EXPANSION PLAN 2047 (IGCEP 2047)",
  },
];
