export const associates= [
    {
        id: "1",
        name: "ADEEL WAHID",
        education: "BSc.(LUMS), J.D(UMichigan)",
        post: "Advocate"
    },
    {
        id: "2",
        name: "ALI HASSAN GILANI",
        education: "B.A-LL.B(Hons)(LUMS)",
        post: ""
    },
    {
        id: "3",
        name: "ATIRA IKRAMN",
        education: "LL.B.(Hons)(London)",
        post: "Advocate High Court"
    },
    {
        id: "4",
        name: "AFTAB ZAFAR",
        education: "M.A., LL.B.(Punjab)",
        post: "Advocate High Court"
    },
    {
        id: "5",
        name: "ARSLAN RIAZ",
        education: "B.A.-LL.B., LL.M. (Punjab)",
        post: "Advocate High Court"
    },
    {
        id: "6",
        name: "BILAL AHMED KHAN",
        education: "LL.B. (Hons) (London)",
        post: "Barrister-at-Law"
    }
]