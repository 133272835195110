import React from "react";
import { associates } from "../../data/associates";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import "./Associates.css";

const Associates = () => {
  return (
    <div className="associates-container">
      <Typography
        className="associate-title"
        sx={{
          textAlign: "center",
          fontFamily: "Montserrat",
          textTransform: "uppercase",
          fontSize: { xs: "36px", sm: "48px", md: "56px" },
          lineHeight: { xs: "44px", sm: "54px", md: "64px" },
          fontWeight: "300",
          padding: "20px 0",
        }}
      >
        Associates
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {associates.map((card) => (
          <Grid item key={card.id} xs={12} sm={6} md={4}>
            <Card
              sx={{
                background: "transparent",
                minHeight: "150px",
                maxWidth: "400px",
                padding: "20px",
                boxShadow: "none",
                color: "white",
              }}
            >
              <CardContent
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography className="associates-name">{card.name}</Typography>
                <Typography>{card.education}</Typography>
                <Typography>{card.post}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Associates;
