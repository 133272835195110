import "./Home.css";
import HOMEIMG from "../../../src/assets/images/Group1.jpg";
import { Typography, Card, CardContent } from "@mui/material";
import Media from "react-media";

function Home() {
  return (
    <>
      <div className="home-wrapper">
        <div id="home" className="home-main">
          <Media queries={{ small: "(max-width:700px)" }}>
            {(size) =>
              size.small ? (
                <Card
                  sx={{
                    width: "85%",
                    position: "absolute",
                    top: "40vh",
                    left: "10vw",
                    backgroundColor: "transparent",
                    color: "white",
                    boxShadow: 0,
                    padding: 0, // Remove padding
                    margin: 0, // Remove margin
                  }}
                >
                  <CardContent sx={{ padding: 0, margin: 0 }}>
                    {" "}
                    {/* Remove padding and margin */}
                    <div className="borders"></div>
                    <Typography
                      variant="h4"
                      component="div"
                      sx={{
                        fontFamily: "serif",
                        font: "22px",
                        fontWeight: 400,
                      }}
                    >
                      Lawfare.Co
                    </Typography>
                    <div className="borders"></div>
                    <Typography variant="h1" color="text.secondary">
                      SINCE 2020
                    </Typography>
                  </CardContent>
                </Card>
              ) : (
                <Card
                  sx={{
                    width: "35%",
                    position: "absolute",
                    top: "40vh",
                    left: "10vw",
                    backgroundColor: "transparent",
                    color: "white",
                    boxShadow: 0,
                    padding: 0, // Remove padding
                    margin: 0, // Remove margin
                  }}
                >
                  <CardContent sx={{ padding: 0, margin: 0 }}>
                    {" "}
                    {/* Remove padding and margin */}
                    {/* <div className="borders"></div> */}
                    <Typography
                      variant="h4"
                      component="div"
                      className="borders"
                      sx={{
                        fontFamily: "Serif",
                        font: "22px",
                        fontWeight: 400,
                      }}
                    >
                      Lawfare.CO
                    </Typography>
                    {/* <div className="borders"></div> */}
                    <Typography variant="h1">SINCE 2020</Typography>
                  </CardContent>
                </Card>
              )
            }
          </Media>
          ;
          <img
            src={HOMEIMG}
            alt="Lawfare"
            className="home-img"
            style={{
              // height: "100%",
              objectFit: "cover",
              position: "relative",
              top: "-19px",
              zIndex: "-100",
            }}
          />
          <div className="gradient-overlay"></div>
        </div>
      </div>
    </>
  );
}

export default Home;
