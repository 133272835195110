import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <Grid container justifyContent="center" className="footer-main-container">
      <Grid item xs={12} sm={6} md={3} className="grid-item">
        <Card
          sx={{
            maxWidth: 300,
            background: "transparent",
            padding: "20px",
            boxShadow: "none",
            color: "white",
          }}
          className="card-main-container"
        >
          <CardContent>
            <Typography>Karachi Office</Typography>
            <br />
            <Typography>
              Address: Office No.200, 2nd Floor
              <br />
              Plot No.5C, Lane No.2
            </Typography>
            <Typography>
              Khayaban-e-Nishat, Phase 6, DHA
              <br />
              Karachi, Pakistan
            </Typography>
            <Typography>T: +92 21 35341012</Typography>
            <Typography>F: +92 21 35341012</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3} className="grid-item">
        <Card
          sx={{
            maxWidth: 300,
            background: "transparent",
            padding: "20px",
            boxShadow: "none",
            color: "white",
          }}
          className="card-main-container"
        >
          <CardContent>
            <Typography>Lahore Office</Typography>
            <br />
            <Typography>
              Address: 33-C Main Gulberg
              <br />
              Lahore, Pakistan
            </Typography>
            <Typography>T: +92 42 35750208 / 35750074</Typography>
            <Typography>F: +92 42 35750175</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3} className="grid-item">
        <Card
          sx={{
            maxWidth: 300,
            background: "transparent",
            padding: "20px",
            boxShadow: "none",
            color: "white",
          }}
          className="card-main-container"
        >
          <CardContent>
            <Typography>Islamabad Office</Typography>
            <br />
            <Typography>
              Address: H. No. 4, Street No. 31
              <br />
              F-7/1, Islamabad, Pakistan
            </Typography>
            <Typography>T: +92 51 2611925</Typography>
            <Typography>F: +92 51 2611926</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3} className="grid-item">
        <Card
          sx={{
            maxWidth: 300,
            background: "transparent",
            padding: "20px",
            boxShadow: "none",
            color: "white",
          }}
          className="card-main-container"
        >
          <CardContent>
            <Typography>Sign Up For News & Briefings</Typography>
            <br />
            <TextField
              label="Email Address"
              variant="outlined"
              type="email"
              fullWidth
              className="text-field"
            />
            <br />
            <Button
              className="subscribe-btn"
              sx={{
                backgroundColor: "#09154e!important",
                color: "white",
                marginTop: "10px",
                fontFamily: "Lora",
                fontSize: "20px",
                fontWeight: "400",
                textTransform: "inherit",
                padding: "5px 20px",
              }}
            >
              Subscribe
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Footer;
